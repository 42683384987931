import styled from "styled-components";
import React from "react";
import { all } from "../styledSystemUtilities";
import _ from "lodash/fp";

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const Td = styled.td`
    color: #7f7f7f;
    height: 35px;

    border-top: ${(props) => props.theme.borders.green};
    border-bottom: ${(props) => props.theme.borders.green};
    text-align: center;
    font-size: 12px;
    ${all}
`;

const TdB = styled(Td)`
    background-color: white;
    width: 52px;
    border-right: ${(props) => props.theme.borders.green};
    ${all}
`;

const TdName = styled(Td)`
    border-left: ${(props) => props.theme.borders.green};
    padding-left: 12px;
    text-align: left;
    ${all}
`;

const Th = styled.th`
    color: #a7a9ac;
    font-size: 9px;
    font-weight: 500;
    padding-bottom: 5px;
    ${all}
`;

const TdA = styled(Td)`
    background-color: #ddebe3;
    width: 52px;
    ${all}
`;

export const KeywordsTable = ({ keywords, competitorCount }) => {
    const hasSearchVolume = _.some((keyword) => keyword.searchVolume > 0, keywords);

    return (
        <Table data-testid={"keywordsTable"}>
            <thead>
                <tr>
                    <Th style={{ textAlign: "left" }}>
                        <br />
                        KEYWORD
                    </Th>
                    {hasSearchVolume ? (
                        <Th whiteSpace={"nowrap"}>
                            MONTHLY
                            <br />
                            SEARCHES:
                        </Th>
                    ) : null}

                    <Th whiteSpace={"nowrap"}>
                        <br />
                        YOUR RANK:
                    </Th>
                </tr>
            </thead>
            <tbody>
                {keywords.map((keyword, index) => {
                    return (
                        <tr key={index} data-testid={`row${index + 1}`}>
                            <TdName>{keyword.googleSearchTerm}</TdName>
                            {hasSearchVolume ? (
                                <TdA>
                                    {new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(
                                        keyword.searchVolume,
                                    )}
                                </TdA>
                            ) : null}

                            <TdB>{keyword.rank === competitorCount + 2 ? `${keyword.rank}+` : keyword.rank}</TdB>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};
