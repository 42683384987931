import React from "react";
import styled, { css } from "styled-components";

import { ReactComponent as RawArrowRight } from "./arrowRight.svg";
import { ReactComponent as RawArrowLeft } from "./arrowLeft.svg";
import { ReactComponent as RawArrowDown } from "./arrowDown.svg";
import { ReactComponent as RawArrowUp } from "./arrowUp.svg";
import { ReactComponent as RawCheck } from "./Check.svg";
import { ReactComponent as RawBigCheck } from "./bigCheck.svg";
import { ReactComponent as RawInfoIcon } from "./infoIcon.svg";
import { ReactComponent as RawQuestionIcon } from "./questionIcon.svg";
import { ReactComponent as RawCarrot } from "./carrot.svg";
import { ReactComponent as RawComputerIcon } from "./computerIcon.svg";
import { ReactComponent as RawGrowthIcon } from "./growthIcon.svg";
import { ReactComponent as RawProfitIcon } from "./profitIcon.svg";
import { ReactComponent as RawLogo } from "./Logo.svg";
import essentialWebsiteHero from "./essentialWebsiteHero.jpg";
import enhancedWebsiteHero from "./enhancedWebsiteHero.jpg";
import reviewsHero from "./reviewsHero.jpg";
import essentialSeoHero from "./essentialSeoHero.jpg";
import enhancedSeoHero from "./enhancedSeoHero.jpg";
import socialSeoHero from "./socialHero.jpg";
import ppcHero from "./ppcHero.jpg";
import postcardHero from "./postcardHero.jpg";
import minizineHero from "./minizineHero.jpg";
import brandHero from "./brandHero.jpg";
import logoHero from "./logoHero.jpg";
import dentalMenuStandardHero from "./dentalMenuStandardHero.jpg";
import dentalMenuPremiumHero from "./dentalMenuPremiumHero.jpg";
import eightPageHero from "./eightPageHero.jpg";
import accordianHero from "./accordianHero.jpg";
import triFoldHero from "./triFoldHero.jpg";
import referralCardsHero from "./referralCardsHero.jpg";
import businessCardsHero from "./businessCardsHero.jpg";
import envelopeHero from "./envelopeHero.jpg";
import letterheadHero from "./letterheadHero.jpg";
import promoCardsHero from "./promoCardsHero.jpg";
import flyersHero from "./flyersHero.jpg";
import presentationFoldersHero from "./presentationFoldersHero.jpg";
import unitasHero from "./unitasHero.png";
import { ReactComponent as RawPin } from "./pin.svg";
import { ReactComponent as RawUpsideDownPin } from "./upsideDownPin.svg";
import { ReactComponent as RawVideoPlaybackIcon } from "./videoPlaybackIcon.svg";
import { ReactComponent as RawCartIcon } from "./cartIcon.svg";
import { ReactComponent as RawCheckedCart } from "./checkedCart.svg";
import { ReactComponent as RawCalculator } from "./calculator.svg";
import { ReactComponent as RawEDentalLogo } from "./eDentalLogo.svg";
import { ReactComponent as RawBurkhartLogo } from "./burkhartLogo.svg";

import { all } from "../../styledSystemUtilities";
import essentialWebsiteSample1 from "./essentialWebsiteSample1.png";
import essentialWebsiteSample2 from "./essentialWebsiteSample2.png";
import essentialWebsiteSample3 from "./essentialWebsiteSample3.png";
import enhancedWebsiteSample1 from "./enhancedWebsiteSample1.png";
import enhancedWebsiteSample2 from "./enhancedWebsiteSample2.png";
import enhancedWebsiteSample3 from "./enhancedWebsiteSample3.png";
import enhancedWebsiteSample from "./enhancedWebsiteSample.png";
import logoSample1 from "./logoSample1.png";
import logoSample2 from "./logoSample2.png";
import logoSample3 from "./logoSample3.png";
import logoSample4 from "./logoSample4.png";
import logoSample5 from "./logoSample5.png";
import logoSample6 from "./logoSample6.png";
import vanessa from "./vanessa.png";
import hillary from "./hillary.png";
import dentalMenuLogo from "./dentalMenuLogo.png";

import { ReactComponent as _SwellLogo } from "./swellMagenta.svg";
import { ReactComponent as _WhiteSwellLogo } from "./swellWhite.svg";

export { ReactComponent as Tools } from "./tools.svg";
export { ReactComponent as BestForDentistryLogo } from "./bestForDentistryLogo.svg";
export { ReactComponent as BestForDentistryWhiteLogo } from "./bestForDentistryWhiteLogo.svg";
export { ReactComponent as BigGrayLogo } from "./BigGrayLogo.svg";
export { ReactComponent as BigSubscriptionIcon } from "./bigSubscriptionIcon.svg";
export { ReactComponent as InsightLogo } from "./insightLogo.svg";
export { ReactComponent as RawGradient } from "./gradientBar.svg";
export { ReactComponent as RawScoreGradient } from "./scoreGradient.svg";
export { ReactComponent as LightStar } from "./lightStar.svg";
export { ReactComponent as DarkStar } from "./darkStar.svg";
export { ReactComponent as ProductDropdownArrow } from "./productDropdownArrow.svg";
export { ReactComponent as DropdownArrowLeft } from "./dropdownArrowLeft.svg";
export { ReactComponent as GaGraphRaw } from "./gaGraph.svg";
export { ReactComponent as GmbGraphRaw } from "./gmbGraph.svg";
export { ReactComponent as ReviewsPlotRaw } from "./reviewsPlot.svg";
export { ReactComponent as ReviewsTableRaw } from "./reviewsTableRaw.svg";
export { ReactComponent as ReviewsOverTimeRaw } from "./reviewsOverTimeRaw.svg";
export { ReactComponent as ReviewsOverTimeTableRaw } from "./reviewsOverTimeTableRaw.svg";
export { ReactComponent as SeoOverTimeRaw } from "./seoOverTimeRaw.svg";
export { ReactComponent as SeoPlotRaw } from "./seoPlot.svg";
export { ReactComponent as SeoTableRaw } from "./seoTable.svg";
export { ReactComponent as VisibilityWheelRaw } from "./visibilityWheel.svg";
export { ReactComponent as VisibilityTableRaw } from "./visibilityTable.svg";
export { ReactComponent as CampaignSummaryRaw } from "./campaignSummary.svg";
export { ReactComponent as GoalPlanningRaw } from "./goalPlanning.svg";
export { ReactComponent as MonthToDateRaw } from "./monthToDate.svg";
export { ReactComponent as PpcTableRaw } from "./ppcTable.svg";
export { ReactComponent as CostPerClickChartRaw } from "./costPerClickChart.svg";
export { ReactComponent as PpcCallTableRaw } from "./ppcCallTable.svg";
export { ReactComponent as PpcCostPerPatientRaw } from "./ppcCostPerPatient.svg";
export { ReactComponent as DirectMailCampaignSummaryRaw } from "./directMailCampaignSummary.svg";
export { ReactComponent as DirectMailGoalPlanningRaw } from "./directMailGoalPlanning.svg";
export { ReactComponent as DirectMailMonthToDateRaw } from "./directMailMonthToDate.svg";
export { ReactComponent as DirectMailTableRaw } from "./directMailTable.svg";
export { ReactComponent as DirectMailCallsRaw } from "./directMailCalls.svg";
export { ReactComponent as DirectMailCostPerPatientRaw } from "./directMailCostPerPatient.svg";
export { ReactComponent as X } from "./x.svg";
export { ReactComponent as RawGoogle } from "./google.svg";
export { ReactComponent as RawFacebook } from "./facebook.svg";
export { ReactComponent as RawResponsive } from "./responsive.svg";
export { ReactComponent as RawPhone } from "./phone.svg";
export { ReactComponent as BluePhone } from "./bluePhone.svg";
export { ReactComponent as RawArt } from "./art.svg";
export { ReactComponent as RawCopyWriting } from "./copyWriting.svg";
export { ReactComponent as RawPhoto } from "./photo.svg";
export { ReactComponent as RawStrategy } from "./strategy.svg";
export { ReactComponent as RawAdvancedSeo } from "./advancedSeo.svg";
export { ReactComponent as RawSeoContent } from "./seoContent.svg";
export { ReactComponent as RawReviewsTracking } from "./reviewsTracking.svg";
export { ReactComponent as RawAirplane } from "./airplane.svg";
export { ReactComponent as DynamicCallTrackingRaw } from "./dynamicCallTracking.svg";
export { ReactComponent as OptimizedLandingPageRaw } from "./optimizedLandingPage.svg";
export { ReactComponent as CompetitiveAnalysisRaw } from "./competitveAnalysis.svg";
export { ReactComponent as ExternalLinkRaw } from "./externalLink.svg";
export { ReactComponent as AdvancedLinkRaw } from "./advancedLink.svg";
export { ReactComponent as PrinterRaw } from "./printer.svg";
export { ReactComponent as ShippingRaw } from "./shipping.svg";
export { ReactComponent as PenRaw } from "./pen.svg";
export { ReactComponent as HostingIcon } from "./hosting.svg";
export { ReactComponent as At } from "./at.svg";
export { ReactComponent as Lock } from "./lock.svg";
export { ReactComponent as Domain } from "./domain.svg";
export { ReactComponent as Maintenance } from "./maintenance.svg";
export { ReactComponent as Insta } from "./insta.svg";
export { ReactComponent as Twitter } from "./twitter.svg";
export { ReactComponent as FilterRaw } from "./filter.svg";
export { ReactComponent as ConversationBubbles } from "./conversationBubbles.svg";
export { ReactComponent as DesktopComputer } from "./desktopComputer.svg";
export { ReactComponent as ComputerWithRing } from "./computerWithRing.svg";
export { ReactComponent as MySocialPracticeLogo } from "./mySocialPracticeLogo.svg";
export { ReactComponent as PuzzlePieces } from "./puzzlePieces.svg";
export { ReactComponent as Target } from "./target.svg";
export { ReactComponent as Heart } from "./heart.svg";
export { ReactComponent as CalendarIcon } from "./calendarIcon.svg";
export { ReactComponent as CameraIcon } from "./cameraIcon.svg";
export { ReactComponent as DoubleArrows } from "./doubleArrows.svg";
export { ReactComponent as KeyBenefits } from "./keyBenefits.svg";
export { ReactComponent as SendIcon } from "./sendIcon.svg";
export { ReactComponent as ListCheck } from "./listCheck.svg";
export { ReactComponent as BoxArrow } from "./boxArrow.svg";
export { ReactComponent as HalfCircle } from "./halfCircle.svg";
export { ReactComponent as LionSpeakLogo } from "./lionSpeakLogo.svg";
export { ReactComponent as LionSpeakProductCardLogo } from "./lionSpeakProductCardLogo.svg";
export { ReactComponent as TwoLevelsEngagement } from "./twoLevelsEngagement.svg";
export { ReactComponent as ThreeLevelsEngagement } from "./threeLevelsEngagement.svg";
export { ReactComponent as TwoPeople } from "./twoPeople.svg";
export { ReactComponent as Cycle } from "./cycle.svg";
export { ReactComponent as TargetSlice } from "./targetSlice.svg";
export { ReactComponent as Ribbon } from "./ribon.svg";
export { ReactComponent as Handshake } from "./handshake.svg";
export { ReactComponent as GreenHandshake } from "./greenHandshake.svg";
export { ReactComponent as GreenDesktopComputer } from "./greenDesktopComputer.svg";
export { ReactComponent as GreenTwoPeople } from "./greenTwoPeople.svg";
export { ReactComponent as CrossHairTarget } from "./crosshairTarget.svg";
export { ReactComponent as Audio } from "./audio.svg";
export { ReactComponent as Pdf } from "./pdf.svg";
export { ReactComponent as Cart } from "./cart.svg";
export { ReactComponent as BigCart } from "./bigCart.svg";
export { ReactComponent as ScreenShare } from "./screenShare.svg";
export { ReactComponent as PlusFour } from "./plusFour.svg";
export { ReactComponent as MagnifyingGlass } from "./magnifyingGlass.svg";
export { ReactComponent as Video } from "./video.svg";
export { ReactComponent as Recording } from "./recording.svg";
export { ReactComponent as Clipboard } from "./clipboard.svg";
export { ReactComponent as GoogleMyBusinessLogo } from "./googleMyBusinessLogo.svg";
export { ReactComponent as GoogleLogo } from "./googleLogo.svg";
export { ReactComponent as LocalLogo } from "./localLogo.svg";
export { ReactComponent as LocalPin } from "./localPin.svg";
export { ReactComponent as ReviewsLogo } from "./reviewsLogo.svg";
export { ReactComponent as AdsLogo } from "./adsLogo.svg";
export { ReactComponent as GrayAscentLogo } from "./grayLogo.svg";
export { ReactComponent as ReviewsStars } from "./reviewsStars.svg";
export { ReactComponent as UnitasProductCardLogo } from "./unitasProductCardLogo.svg";
export { ReactComponent as EmptyCart } from "./emptyCart.svg";
export { ReactComponent as GreenCart } from "./greenCart.svg";
export { ReactComponent as PhoneIcon } from "./phoneIcon.svg";
export { ReactComponent as BlueCart } from "./blueCart.svg";
export { ReactComponent as NauticCart } from "./nauticCart.svg";
export { ReactComponent as RawReverseGradient } from "./reverseGradient.svg";
export { ReactComponent as FullDownArrow } from "./fullArrowDown.svg";
export { ReactComponent as AdsIcon } from "./adsIcon.svg";
export { ReactComponent as CreditCardIcon } from "./creditCardIcon.svg";
export { ReactComponent as ApplePayIcon } from "./applePayIcon.svg";
export { ReactComponent as AdobeIcon } from "./adobeIcon.svg";
export { ReactComponent as SiennaTriangle } from "./siennaTriangle.svg";
export { ReactComponent as BurkhartLogo } from "./burkhartLogo.svg";
export { ReactComponent as BurkhartLogoSmall } from "./burkhartLogoSmall.svg";
export { ReactComponent as EDentWithBurkhart } from "./eDentWithBurkhart.svg";
export { ReactComponent as HPALogo } from "./hpaLogo.svg";

export const Vanessa = () => {
    return <img src={vanessa} alt={"Vanessa"} width={"88px"} />;
};

export const Hillary = () => {
    return <img src={hillary} alt={"Vanessa"} width={"88px"} />;
};

export const EssentialWebsiteHero = () => {
    return <img src={essentialWebsiteHero} alt={"EssentialWebsiteHero"} width={"100%"} />;
};

export const EnhancedWebsiteHero = () => {
    return <img src={enhancedWebsiteHero} alt={"EnhancedWebsiteHero"} width={"100%"} />;
};

export const ReviewsHero = () => {
    return <img src={reviewsHero} alt={"ReviewsHero"} width={"100%"} />;
};

export const EssentialSeoHero = () => {
    return <img src={essentialSeoHero} alt={"EssentialSeoHero"} width={"100%"} />;
};

export const BrandHero = () => {
    return <img src={brandHero} alt={"BrandHero"} width={"100%"} />;
};

export const LogoHero = () => {
    return <img src={logoHero} alt={"LogoHero"} width={"100%"} />;
};

export const DentalMenuStandardHero = () => {
    return <img src={dentalMenuStandardHero} alt={"Dental Menu"} width={"100%"} />;
};

export const DentalMenuPremiumHero = () => {
    return <img src={dentalMenuPremiumHero} alt={"Dental Menu"} width={"100%"} />;
};

export const PostcardHero = () => {
    return <img src={postcardHero} alt={"PostcardHero"} width={"100%"} />;
};

export const MinizineHero = () => {
    return <img src={minizineHero} alt={"MinizineHero"} width={"100%"} />;
};

export const UnitasHero = () => {
    return <img src={unitasHero} alt={"UnitasHero"} width={"100%"} />;
};

export const EightPageHero = () => {
    return <img src={eightPageHero} alt={"EightPageHero"} width={"100%"} />;
};

export const AccordianHero = () => {
    return <img src={accordianHero} alt={"AccordianHero"} width={"100%"} />;
};

export const TriFoldHero = () => {
    return <img src={triFoldHero} alt={"TriFoldHero"} width={"100%"} />;
};

export const ReferralCardsHero = () => {
    return <img src={referralCardsHero} alt={"ReferralCardsHero"} width={"100%"} />;
};

export const BusinessCardsHero = () => {
    return <img src={businessCardsHero} alt={"BusinessCardsHero"} width={"100%"} />;
};

export const EnvelopeHero = () => {
    return <img src={envelopeHero} alt={"EnvelopeHero"} width={"100%"} />;
};

export const LetterheadHero = () => {
    return <img src={letterheadHero} alt={"LetterheadHero"} width={"100%"} />;
};

export const PromoCardsHero = () => {
    return <img src={promoCardsHero} alt={"PromoCardsHero"} width={"100%"} />;
};

export const FlyersHero = () => {
    return <img src={flyersHero} alt={"FlyersHero"} width={"100%"} />;
};

export const PresentationFoldersHero = () => {
    return <img src={presentationFoldersHero} alt={"PresentationFoldersHero"} width={"100%"} />;
};

export const EnhancedSeoHero = () => {
    return <img src={enhancedSeoHero} alt={"EnhancedSeoHero"} width={"100%"} />;
};

export const SocialSeoHero = () => {
    return <img src={socialSeoHero} alt={"SocialSeoHero"} width={"100%"} />;
};

export const PpcHero = () => {
    return <img src={ppcHero} alt={"PpcHero"} width={"100%"} />;
};

export const EssentialWebsiteSample1 = () => {
    return <img src={essentialWebsiteSample1} alt={"EssentialWebsiteSample1"} width={"100%"} />;
};

export const EssentialWebsiteSample2 = () => {
    return <img src={essentialWebsiteSample2} alt={"EssentialWebsiteSample2"} width={"100%"} />;
};

export const EssentialWebsiteSample3 = () => {
    return <img src={essentialWebsiteSample3} alt={"EssentialWebsiteSample3"} width={"100%"} />;
};

export const EnhancedWebsiteSample1 = () => {
    return <img src={enhancedWebsiteSample1} alt={"EnhancedWebsiteSample1"} width={"100%"} />;
};

export const EnhancedWebsiteSample2 = () => {
    return <img src={enhancedWebsiteSample2} alt={"EnhancedWebsiteSample2"} width={"100%"} />;
};

export const EnhancedWebsiteSample3 = () => {
    return <img src={enhancedWebsiteSample3} alt={"EnhancedWebsiteSample3"} width={"100%"} />;
};

export const EnhancedWebsiteSample = () => {
    return <img src={enhancedWebsiteSample} alt={"EnhancedWebsiteSample"} width={"100%"} />;
};

export const LogoSample1 = () => {
    return <img src={logoSample1} alt={"LogoSample1"} width={"100%"} />;
};

export const LogoSample2 = () => {
    return <img src={logoSample2} alt={"LogoSample2"} width={"100%"} />;
};

export const LogoSample3 = () => {
    return <img src={logoSample3} alt={"LogoSample3"} width={"100%"} />;
};

export const LogoSample4 = () => {
    return <img src={logoSample4} alt={"LogoSample4"} width={"100%"} />;
};

export const LogoSample5 = () => {
    return <img src={logoSample5} alt={"LogoSample5"} width={"100%"} />;
};

export const LogoSample6 = () => {
    return <img src={logoSample6} alt={"LogoSample6"} width={"100%"} />;
};

export const DentalMenuLogo = () => {
    return <img src={dentalMenuLogo} alt={"Dental Menu"} width={"262px"} />;
};

const color = css`
    fill: ${(props) => props.theme.colors[props.fill]};
`;

export const SwellLogo = styled(_SwellLogo)`
    ${all}
`;

export const WhiteSwellLogo = styled(_WhiteSwellLogo)`
    ${all}
`;

export const ArrowLeft = styled(RawArrowLeft)`
    ${color}
`;
export const ArrowRight = styled(RawArrowRight)`
    ${color}
`;
export const ArrowDown = styled(RawArrowDown)`
    ${color}
`;
export const ArrowUp = styled(RawArrowUp)`
    ${color}
`;
export const Check = styled(RawCheck)`
    ${color}
`;

export const BigCheck = styled(RawBigCheck)`
    ${color}
`;
export const InfoIcon = styled(RawInfoIcon)`
    :hover {
        cursor: pointer;
    }
    ${color}
`;

export const QuestionIcon = styled(RawQuestionIcon)`
    :hover {
        cursor: pointer;
    }
    ${color}
    ${all}
`;

export const Pin = styled(RawPin)`
    ${all}
`;

export const UpsideDownPin = styled(RawUpsideDownPin)`
    ${all}
`;

export const Logo = styled(RawLogo)`
    ${all}
`;

export const ComputerIcon = styled(RawComputerIcon)`
    ${color}
`;

export const VideoPlaybackIcon = styled(RawVideoPlaybackIcon)`
    ${color}
`;

export const GrowthIcon = styled(RawGrowthIcon)`
    ${color}
`;

export const ProfitIcon = styled(RawProfitIcon)`
    ${color}
`;

export const Calculator = styled(RawCalculator)`
    ${color}
`;

export const Carrot = styled(RawCarrot)`
    ${all}
`;

export const BurkhartLogo2 = styled(RawBurkhartLogo)`
    ${all}
`;

const fill = css`
    path {
        stroke: ${(props) => props.theme.colors[props.fill]};
    }
`;

export const CartIcon = styled(RawCartIcon)`
    ${fill}
`;

export const CheckedCart = styled(RawCheckedCart)`
    ${fill}
`;

export const EDentalLogo = styled(RawEDentalLogo)`
    ${fill}
`;

export const DynamicCart = ({ fill, isChecked }) =>
    isChecked ? <CheckedCart fill={fill} /> : <CartIcon fill={fill} />;
