import { createSlice } from "@reduxjs/toolkit";

export default createSlice({
    name: "strategist",
    initialState: {
        name: "",
        email: "",
        id: "",
    },
    reducers: {
        set: (state, action) => action.payload,
    },
});
