import { useSelector } from "react-redux";
import { BestForDentistryWhiteLogo, HPALogo, Logo, SwellLogo, WhiteSwellLogo } from "./assets";
import React from "react";

export const MenuLogo = () => {
    const isBestForDentistry = useSelector((state) => state.isBestForDentistry);
    const isHpa = useSelector((state) => state.isHpa);
    const isSwell = useSelector((state) => state.isSwell);

    return isBestForDentistry ? (
        <BestForDentistryWhiteLogo />
    ) : isHpa ? (
        <HPALogo />
    ) : isSwell ? (
        <WhiteSwellLogo width={"180px"} />
    ) : (
        <Logo width={"158px"} />
    );
};
