import React, { useEffect, useState } from "react";
import { Gradient, Text } from "../Atoms";
import { Box } from "../styledSystemUtilities";
import { Transition } from "react-transition-group";
import { GrowthHorizontalPhaseLabel, OnlineHorizontalPhaseLabel } from "../Molecules/HorizontalPhaseLabels";
import clouds from "../Atoms/assets/descriptionClouds.jpg";
import { useWindowSize } from "react-use";
import { BannerGapFiller, GrowthPhaseCard, OnlinePhaseCard } from "../Molecules/PhaseCards";
import { LeftBanner, RightBanner } from "../Atoms/BannerComponents";

export const Description = () => {
    const [boldHeader, setBoldHeader] = useState(false);
    const [body, setBody] = useState(false);

    const boldHeaderStyles = {
        entering: { opacity: 1, transform: `translate(0, 0)` },
        entered: { opacity: 1, transform: `translate(0, 0)` },
        exiting: { opacity: 0, transform: `translate(0, -25px)` },
        exited: { opacity: 0, transform: `translate(0, -25px)` },
    };
    const bodyStyles = {
        entering: { opacity: 1 },
        entered: { opacity: 1 },
        exiting: { opacity: 0 },
        exited: { opacity: 0 },
    };

    const { height, width } = useWindowSize();

    const isMobile = width < 1165;
    const mobileColumnWidth = width < 375 ? width + "px" : "375px";

    useEffect(() => {
        const boldTimer = setTimeout(() => setBoldHeader(true), 2000);
        const bodyTimer = setTimeout(() => setBody(true), 4000);

        return () => {
            clearTimeout(boldTimer);
            clearTimeout(bodyTimer);
        };
    }, []);

    return (
        <Box height={"100vh"}>
            <Gradient />
            <Box pb={12} />
            <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                <Text variant={"header2"} textAlign={"center"} mx={6}>
                    Your custom report is ready!
                </Text>
                <Box pb={12} />
                <Transition in={boldHeader} timeout={1000}>
                    {(state) => (
                        <Text
                            variant={"header2"}
                            fontWeight={"semiBold"}
                            transition={"all 1000ms"}
                            textAlign={"center"}
                            mx={12}
                            style={{ ...boldHeaderStyles[state] }}
                        >
                            Where would you like to begin?
                        </Text>
                    )}
                </Transition>
            </Box>
            <Box pb={15} />
            <Transition in={body} timeout={1000}>
                {(state) => (
                    <Box transition={"all 1000ms"} style={{ ...bodyStyles[state] }}>
                        {isMobile ? (
                            <Box position={"relative"} zIndex={1}>
                                <Box display={"flex"} justifyContent={"center"}>
                                    <Box width={mobileColumnWidth} pl={"18px"}>
                                        <OnlineHorizontalPhaseLabel />
                                    </Box>
                                </Box>
                                <Box
                                    position={"relative"}
                                    backgroundImage={`url(${clouds})`}
                                    backgroundPosition={"center"}
                                    backgroundSize={"cover"}
                                    zIndex={-2}
                                    display={"flex"}
                                    flexDirection={"column"}
                                    alignItems={"center"}
                                    minHeight={`${height - 307}px`}
                                >
                                    <Box display={"flex"} width={"100%"}>
                                        <LeftBanner
                                            frontColor={"seaGreen"}
                                            backColor={"forrestGreen"}
                                            hideBackgroundImage={true}
                                        />
                                        <OnlinePhaseCard width={mobileColumnWidth} />
                                        <RightBanner frontColor={"seaGreen"} backColor={"forrestGreen"} />
                                    </Box>

                                    <Box width={mobileColumnWidth} pl={"10px"} pt={"26px"}>
                                        <GrowthHorizontalPhaseLabel />
                                    </Box>

                                    <Box display={"flex"} width={"100%"}>
                                        <LeftBanner frontColor={"ipanemaBlue"} backColor={"deepPetrol"} />
                                        <GrowthPhaseCard width={mobileColumnWidth} />
                                        <RightBanner frontColor={"ipanemaBlue"} backColor={"deepPetrol"} />
                                    </Box>

                                    <Box pb={"40px"} />
                                    {/*<SaveYourPlanDescription />*/}

                                    <Box pb={"80px"} />
                                </Box>
                            </Box>
                        ) : (
                            <Box position={"relative"} zIndex={1}>
                                <Box display={"flex"}>
                                    <Box pl={`${(width - 780) / 2 + 16}px`} />
                                    <OnlineHorizontalPhaseLabel />
                                    <Box pl={"170px"} />
                                    <GrowthHorizontalPhaseLabel />
                                </Box>
                                <Box
                                    backgroundImage={`url(${clouds})`}
                                    backgroundPosition={"center"}
                                    backgroundSize={"cover"}
                                    height={`${height - 307}px`}
                                    minHeight={"420px"}
                                    display={"flex"}
                                    flexDirection={"column"}
                                >
                                    <Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                                        <LeftBanner
                                            frontColor={"seaGreen"}
                                            backColor={"forrestGreen"}
                                            hideBackgroundImage={true}
                                        />
                                        <OnlinePhaseCard />

                                        <BannerGapFiller
                                            startBackColor={"forrestGreen"}
                                            startFrontColor={"seaGreen"}
                                            finishColor={"ipanemaBlue"}
                                        />
                                        <GrowthPhaseCard />
                                        {/*<ProfitPhaseCard />*/}

                                        <RightBanner backColor={"deepPetrol"} frontColor={"ipanemaBlue"} />
                                    </Box>
                                    <Box pb={"80px"} />
                                    {/*<SaveYourPlanDescription />*/}
                                </Box>
                            </Box>
                        )}
                    </Box>
                )}
            </Transition>
        </Box>
    );
};
