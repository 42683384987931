import React, { useState } from "react";
import { Button, Gradient, Text } from "../Atoms";
import { Box } from "../styledSystemUtilities";
import { useDispatch, useSelector } from "react-redux";
import { routerHistory } from "../../routerHistory";
import { GoogleMyBusinessLogo } from "../Atoms/assets";
import { BigCheckbox } from "../Atoms/Checkbox";
import { actions } from "../../redux";
// const places = [
//     {
//         placeId: "ChIJiYASwxFZUYgRRi2ARAErgJc",
//         rating: "4.7",
//         practiceName: "Carolina Crossroads Dental Care",
//         address: "1316 Davie Ave Suite B, Statesville, NC 28677, United States",
//         numReviews: "32",
//     },
// ];

export const PlaceSelect = () => {
    const dispatch = useDispatch();
    const [selectedPlace, setSelectedPlace] = useState({});
    const [error, setError] = useState(null);
    const places = useSelector((state) => state.places);
    const user = useSelector((state) => state.user);

    const handleSubmit = () => {
        if (!selectedPlace.placeId) {
            setError((prevState) => "Please select a location.");
        } else if (selectedPlace.placeId === "noLocation") {
            console.log("noLocation");
        } else {
            dispatch(actions.getReport(selectedPlace, user));
            routerHistory.push("/report-loading");
        }
    };

    return (
        <>
            <Gradient />
            <Box display={"grid"} gridTemplateColumns={"1fr 10px minmax(300px, 330px) 10px 1fr"}>
                <Box display={"flex"} flexDirection={"column"} gridColumn={"3"}>
                    <Box pb={16} />
                    <Text variant={"header2"} color={"graphiteGray"} lineHeight={"33px"}>
                        Almost ready to build your custom report...
                    </Text>
                    <Box pb={5} />
                    <Text variant={"body3"} color={"graphiteGray"} fontWeight={"medium"} lineHeight={"22px"}>
                        Let’s just verify we have the right location.
                    </Text>
                    <Box pb={10} />
                    <Text variant={"body3"} color={"graphiteGray"} fontWeight={"bold"}>
                        Select your listing:
                    </Text>
                    <Box pb={7} />
                    <GoogleMyBusinessLogo />
                    <Box pb={9} />
                    {places.map((place) => {
                        return (
                            <Box
                                data-testid={place.placeId}
                                cursor={"pointer"}
                                onClick={() => {
                                    handlePlaceClick(place, selectedPlace, setSelectedPlace, setError);
                                }}
                                key={place.placeId}
                                pb={4}
                            >
                                <Place place={place} isChecked={place.placeId === selectedPlace.placeId} />
                            </Box>
                        );
                    })}
                    <Box pb={"18px"} />
                    <Box
                        cursor={"pointer"}
                        onClick={() => {
                            handlePlaceClick({ placeId: "noLocation" }, selectedPlace, setSelectedPlace, setError);
                        }}
                    >
                        <NoLocationRow isChecked={"noLocation" === selectedPlace.placeId} />
                    </Box>
                    <Box pb={10} />

                    <Button backgroundColor={"graphiteGray"} onClick={handleSubmit} data-testid={"viewResults"}>
                        gather data & design your plan
                    </Button>
                    <Box pt={5} width={"50%"} alignSelf={"flex-end"}>
                        <Text variant={"body3"} color={"sienna"} textAlign={"center"}>
                            {error}
                        </Text>
                    </Box>
                    <Box pb={8} />
                    <Text variant={"body3"}>
                        Tap the button above and we’ll collaborate with Google and other public data aggregates to build
                        an intelligent, custom report for you.
                    </Text>
                    <Box pb={7} />
                    <Text variant={"body3"} fontWeight={"bold"}>
                        It may take a few seconds.
                    </Text>
                </Box>
            </Box>
        </>
    );
};

function Place({ place, isChecked }) {
    const splitAddress = place.address.split(",");

    return (
        <Box display={"flex"}>
            <BigCheckbox isChecked={isChecked} fill={"sienna"} mt={"5px"} />
            <Box pl={5} />
            <Box>
                <Text variant={"body3"} color={"graphiteGray"} fontWeight={"bold"}>
                    {place.practiceName}
                </Text>
                <Text variant={"body4"} color={"graphiteGray"}>
                    {splitAddress[0]}
                </Text>
                <Text variant={"body4"} color={"graphiteGray"}>
                    {[...splitAddress[1], ",", ...splitAddress[2]]}
                </Text>
            </Box>
        </Box>
    );
}

function NoLocationRow({ isChecked }) {
    return (
        <Box display={"flex"} alignItems={"center"}>
            <BigCheckbox isChecked={isChecked} fill={"sienna"} />
            <Box pl={5} />
            <Text variant={"body3"} fontWeight={"bold"} color={"graphiteGray"}>
                My location did not appear
            </Text>
        </Box>
    );
}

function handlePlaceClick(place, selectedPlace, setSelectedPlace, setError) {
    setError((prevState) => null);
    if (place.placeId === selectedPlace.placeId) {
        setSelectedPlace((prevState) => ({}));
    } else {
        setSelectedPlace((prevState) => place);
    }
}
