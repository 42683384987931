import slices from "../slices";
import { api } from "../utilityActions";
import actions from "./index";

const getStrategist = () => (dispatch, getState) => {
    const store = getState();
    const practiceInfo = {
        practiceName: store.user.practiceName,
        type: store.user.practiceType,
        perPatientValue: store.perPatientValue,
        firstName: store.account.firstName,
        lastName: store.account.lastName,
        phone: store.account.phone,
        email: store.account.email,
        placeId: store.report.seo.placeId,
        recurlyAccountCode: store.invoice.chargeInvoice.account.code,
        clientAccountId: store.account.accountId,
        tasks: store.tasks,
    };
    const defaultStrategistInfo = {
        name: "Hillary Owens",
        email: "hillary.o@ascentpgs.com",
        id: "5eb45c40ecde750004bb547e",
    };
    dispatch(
        api({
            call: {
                url: "/getStrategist",
                method: "get",
            },
            success: (strategist) => [
                slices.strategist.actions.set(strategist),
                actions.createPracticeAndUpdateAccount({ ...practiceInfo, strategistId: strategist.idle }),
            ],
            failure: () => [
                slices.strategist.actions.set(defaultStrategistInfo),
                actions.createPracticeAndUpdateAccount({ ...practiceInfo, strategistId: defaultStrategistInfo.id }),
            ],
        }),
    );
};

export default getStrategist;
