import axios from "axios";
import _ from "lodash/fp";
import { slices } from "../index";
import { api } from "../utilityActions";

const apiMiddleware =
    ({ dispatch, getState }) =>
    (next) =>
    async (action) => {
        if (action.type !== api.type) {
            return next(action);
        }
        const { call, success, failure } = action.payload;

        try {
            dispatch(slices.loading.actions.set(true));

            const { data } = await axios({ ...call, url: "/secure" + call.url });
            if (success) {
                dispatch(success(data));
            }
            dispatch(slices.loading.actions.set(false));
            dispatch(slices.error.actions.set(null));
        } catch (e) {
            dispatch(slices.loading.actions.set(false));
            dispatch(slices.error.actions.set(_.get("response.data", e)));
            if (failure) {
                dispatch(failure(e.response));
            }
            console.log(e.response);
        }
    };

export default apiMiddleware;
