import { useSelector } from "react-redux";
import { BestForDentistryLogo, BigGrayLogo, HPALogo, SwellLogo } from "./assets";
import React from "react";

export const WelcomeLogo = () => {
    const isBestForDentistry = useSelector((state) => state.isBestForDentistry);
    const isHpa = useSelector((state) => state.isHpa);
    const isSwell = useSelector((state) => state.isSwell);

    return isBestForDentistry ? (
        <BestForDentistryLogo />
    ) : isHpa ? (
        <HPALogo />
    ) : isSwell ? (
        <SwellLogo width={"240px"} />
    ) : (
        <BigGrayLogo />
    );
};
