import React, { useRef } from "react";
import { OnlinePageTemplate } from "./PageTemplate";
import { Text } from "../Atoms";
import { Box, Span } from "../styledSystemUtilities";
import { ArrowRight, LocalPin, ReviewsStars } from "../Atoms/assets";
import { RankIndicator, StartFromLeftPinPlacement } from "../Atoms/CompetitionLevelIndicator";
import { ReverseGradient } from "../Atoms/Gradient";
import { ReviewsChartCard, SeoChartCard, SeoKeywordsTableCard, TableCard } from "../Organisms";
import { CompetitorsTable } from "../Molecules/CompetitorsTable";
import _ from "lodash/fp";
import { OnlineHeader, OnlineSubHeader } from "../Atoms/Headers";
import { useSelector } from "react-redux";
import { getVisibilityLevel } from "../../utils/getVisibilityLevel";

export const OnlineOne = () => {
    const seoRef = useRef(0);
    const reviewsRef = useRef(0);

    const report = useSelector((state) => state.report);
    const user = useSelector((state) => state.user);

    const competitionLevel = _.get("competitionLevel", report) > 1 ? 1 : _.get("competitionLevel", report);

    const handleScrollClick = (ref) => {
        window.scrollTo(0, ref.current.offsetTop);
    };

    const visibilityLevel = getVisibilityLevel(report);

    return report ? (
        <OnlinePageTemplate hideSummary={"true"}>
            <Box pt={"26px"} />
            <OnlineHeader textAlign={"center"}>Online Visibility Data</OnlineHeader>
            <OnlineSubHeader data-testid={"practiceName"}>{_.get("user.practiceName", report)}</OnlineSubHeader>
            <Box pt={"15px"} />
            {/*<CircleHeader*/}
            {/*    number={1}*/}
            {/*    color={"forrestGreen"}*/}
            {/*    text={"How visible are you online compared to the competition in your area?"}*/}
            {/*/>*/}
            {/*<Box pt={"27px"} />*/}
            <Box backgroundColor={"white"} py={"15px"} data-testid={"visibilityLevelContainer"}>
                <Text textAlign={"center"} variant={"body3"} color={"auratiumGreen"} lineHeight={"20px"}>
                    Your current cumulative online visibility is:
                </Text>
                <Text textAlign={"center"} variant={"header2"} color={"sienna"} lineHeight={"33px"}>
                    {_.capitalize(visibilityLevel)}
                </Text>
                <Text textAlign={"center"} variant={"body3"} color={"auratiumGreen"} lineHeight={"20px"}>
                    Ideal should be the goal
                </Text>
            </Box>
            <Box pb={"20px"} />
            <RankCard
                dataTestId={"localVisibilityCard"}
                Logo={LocalPin}
                name={"Google Local Visibility"}
                message={`There are ${_.get("seo.overallRank", report) - 1} practices easier to find than you.`}
                topCompetitor={_.get("seo.competitors.0.practiceName", report)}
                rank={_.get("seo.overallRank", report)}
                numCompetitors={_.size(_.get("seo.competitors", report)) + 1}
                startAnimationTimeout={0}
                type={"Visibility"}
                link={() => handleScrollClick(seoRef)}
            />
            <Box pb={"20px"} />
            <RankCard
                dataTestId={"reviewsCard"}
                Logo={ReviewsStars}
                name={"Google Reviews"}
                message={`There are ${_.get("reviews.reviewsRank", report) - 1} practices with more reviews than you.`}
                type={"Reviews"}
                numCompetitors={_.size(_.get("reviews.competitors", report)) + 1}
                rank={_.get("reviews.reviewsRank", report)}
                topCompetitor={_.get("reviews.competitors.0.practiceName", report)}
                startAnimationTimeout={1000}
                link={() => handleScrollClick(reviewsRef)}
            />
            <Box pb={"20px"} />
            <Box backgroundColor={"white"} py={"15px"} px={"20px"} data-testid={"competitiveLevelCard"}>
                <Text variant={"body3"} color={"auratiumGreen"} lineHeight={"18px"}>
                    The higher the competitive level for your zip code, the more difficult It is to achieve an ideal
                    ranking.
                </Text>
                <Box pt={"6px"} />
                <Text variant={"body3"} color={"sienna"} lineHeight={"20px"}>
                    Competition level for <Span fontWeight={"bold"}>{user.isCanada ? user.city : user.zipCode}</Span>:
                </Text>
                <Box>
                    <StartFromLeftPinPlacement
                        percent={competitionLevel * 100}
                        score={_.round(competitionLevel * 100)}
                        formatter={(score) => `${score}%`}
                        increment={1}
                        isVisible={false}
                        startAnimationTimeout={1500}
                    />
                    <ReverseGradient width={"100%"} height={"10px"} />
                    <Box mb={3} />
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Text variant={"body4"} color={"auratiumGreen"}>
                            Low
                        </Text>
                        <Text variant={"body4"} color={"auratiumGreen"}>
                            High
                        </Text>
                    </Box>
                </Box>
            </Box>
            <Box pb={"24px"} />
            <Box display={"flex"} justifyContent={"center"}>
                <Text variant={"body4"} color={"forrestGreen"} width={"274px"} textAlign={"center"}>
                    Scroll down to see who’s ranking above you and how we intelligently calculated this data.{" "}
                </Text>
            </Box>
            <Box pb={"24px"} />
            {/*<Button backgroundColor={"forrestGreen"} onClick={() => routerHistory.push("/online-two")}>*/}
            {/*    continue*/}
            {/*</Button>*/}
            {/*<Box pb={"60px"} />*/}
            <Box ref={seoRef} />
            <OnlineHeader pl={"20px"}>Visibility Data Details</OnlineHeader>
            <Box pb={"10px"} />
            <ReportingHeader Logo={LocalPin} name={"Google Local"} message={"Where people search for a new dentist."} />
            <TableCard
                header={"Your Market’s Top 5 Competitors & You"}
                body={
                    "Your overall rank shows how easy it is to see your listing compared to your competition in Google Local search."
                }
                renderTable={() => (
                    <CompetitorsTable
                        competitors={_.get("charts.seoChartData.competitorArray", report)}
                        topCompData={_.get("charts.seoChartData.topCompData", report)}
                        target={_.get("charts.seoChartData.userData", report)}
                        headings={["COMPETITORS:", "OVERALL RANK:"]}
                        dataTestId={"visibilityTable"}
                    />
                )}
            />
            <SeoChartCard seoChartData={_.get("charts.seoChartData", report)} />
            <SeoKeywordsTableCard
                keywords={_.get("seo.rankingsFromGoogleSearches", report)}
                competitorCount={report.seo.competitors.length}
            />
            <Box pb={"24px"} />
            <Box ref={reviewsRef} />
            <OnlineHeader pl={"20px"}>Reviews Data Details</OnlineHeader>
            <Box pb={"10px"} />
            <ReportingHeader
                Logo={ReviewsStars}
                name={"Google Reviews"}
                message={"Ensure trust and improve visibility."}
            />
            <ReviewsChartCard reviewsChartData={_.get("charts.reviewsChartData", report)} />
            <TableCard
                header={"Review Rating, Totals & Your Competition"}
                body={
                    "The quality and quantity of your reviews compared to your competition affect your ranking and perceived trust level."
                }
                renderTable={() => (
                    <CompetitorsTable
                        competitors={_.get("charts.reviewsChartData.compData", report)}
                        topCompData={_.get("charts.reviewsChartData.topCompData", report)}
                        target={_.get("charts.reviewsChartData.userData", report)}
                        headings={["COMPETITORS:", "AVG. RATING", "TOTAL #"]}
                        order={"desc"}
                        dataTestId={"reviewsTable"}
                    />
                )}
            />
            <Box pb={"20px"} />
            {/*<Button backgroundColor={"forrestGreen"} onClick={() => routerHistory.push("/online-two")}>*/}
            {/*    continue*/}
            {/*</Button>*/}
            <Box pb={"100px"} />
        </OnlinePageTemplate>
    ) : null;
};

const ReportingHeader = ({ Logo, name, message }) => (
    <Box backgroundColor={"#DDEBE3"} py={"15px"} px={"20px"}>
        <Box display={"flex"}>
            <Logo />
            <Box pl={"10px"} />
            <Box>
                <Text fontSize={"20px"} fontFamily={"montserrat"} color={"forrestGreen"} lineHeight={"24px"}>
                    {name}
                </Text>
                <Box pb={"2px"} />
                <Text variant={"body3"} fontWeight={"bold"} lineHeight={"18px"} color={"forrestGreen"}>
                    {message}
                </Text>
            </Box>
        </Box>
    </Box>
);

const RankCard = ({
    name,
    Logo,
    type,
    message,
    rank,
    numCompetitors,
    topCompetitor,
    link,
    dataTestId,
    startAnimationTimeout,
}) => {
    return (
        <Box backgroundColor={"white"} py={"15px"} px={"20px"} data-testid={dataTestId}>
            <Box display={"flex"} alignItems={"center"}>
                <Logo />
                <Box pl={"8px"} />
                <Text fontFamily={"montserrat"} fontSize={"16px"} fontWeight={"medium"}>
                    {name}
                </Text>
            </Box>
            <Box pt={"4px"} />
            <Text variant={"body3"} color={"auratiumGreen"}>
                {message}
            </Text>
            <Box pt={"6px"} />
            <Text fontFamily={"montserrat"} fontSize={"20px"} color={"sienna"}>
                Your {type} Rank:
            </Text>
            <RankIndicator rank={rank} max={numCompetitors} startAnimationTimeout={startAnimationTimeout} />
            <Box pt={"11px"} />
            <Text variant={"body4"} color={"auratiumGreen"}>
                Top competitor:{" "}
                <Span variant={"body4"} color={"sienna"}>
                    {topCompetitor}
                </Span>
            </Text>
            <Box display={"flex"} pt={"8px"} onClick={() => link()}>
                <Text variant={"label1"} color={"forrestGreen"} fontWeight={"bold"} cursor={"pointer"} uppercase>
                    View details & other top competitors
                </Text>
                <Box pl={"8px"} cursor={"pointer"} />
                <ArrowRight fill={"forrestGreen"} cursor={"pointer"} />
            </Box>
        </Box>
    );
};
