import React from "react";
import { Box, FlexCol, FlexRowAlignCenter, Grid, Span } from "../styledSystemUtilities";
import { Button, Gradient, Text } from "../Atoms";
import { CheckoutHeader } from "../Molecules/CheckoutHeader";
import { routerHistory } from "../../routerHistory";
import { useDispatch, useSelector } from "react-redux";
import { BigSubscriptionIcon, ComputerIcon, GrowthIcon, ProfitIcon } from "../Atoms/assets";
import { formatter } from "../../utils/formatter";
import _ from "lodash/fp";
import { useOnlineEstimates } from "../../hooks/useOnlineEstimates";
import { useGrowthEstimates } from "../../hooks/useGrowthEstimates";
import { ProfitTotal, SubscriptionTotal } from "../Molecules/SubscriptionTotal";
import { AbsolutePositionLogo } from "../Atoms/AbsolutePositionLogo";
import { actions, slices } from "../../redux";
import useCoupon from "../../hooks/useCoupon";
import coupons from "../../constants/coupons";
import createTaskObject from "../../utils/createTaskObject";
import { subscriptionProductsExcludedFromFreeTrial } from "../../constants/subscriptionProductsExcludedFromFreeTrial";
import { ppcAverages } from "../../constants/ppcAverages";

export const ReviewOrder = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const account = useSelector((state) => state.account);
    const profitPaymentOption = useSelector((state) => state.profitPaymentOption);
    const token = useSelector((state) => state.recurlyToken);
    const cart = useSelector((state) => state.cart);

    const groupedCart = _.groupBy("type", cart);
    const onlineEstimates = useOnlineEstimates(groupedCart.online);
    const growthEstimates = useGrowthEstimates(groupedCart.growth);
    const profitTotalCost = _.sumBy("price", groupedCart.profit);

    const [coupon, couponCode] = useCoupon(groupedCart.online, groupedCart.profit, groupedCart.growth);
    const hasFreeTrial = couponCode === "freetrial";

    const subscriptionProducts = hasFreeTrial
        ? _.filter((product) => subscriptionProductsExcludedFromFreeTrial.includes(product.recurlyCode), cart)
        : _.reject((product) => product.type === "profit", cart);

    const freeTrialSubscriptionProducts = _.reject(
        (product) =>
            product.type === "profit" || subscriptionProductsExcludedFromFreeTrial.includes(product.recurlyCode),
        cart
    );

    const couponCodes = coupons[couponCode] ? [couponCode] : [];

    const productTasks = _.flow(
        _.flatMap((product) => _.map((task) => createTaskObject(task, product.name), product.tasks)),
        _.uniqBy("taskKey")
    )(cart);

    const defaultTasks = [
        {
            taskKey: "googleMyBusiness",
            taskName: "Link Google My Business Account",
            parentProduct: "Reporting Setup",
            completed: false,
        },
    ];

    const tasks = productTasks.concat(defaultTasks);

    const subscriptionPlan =
        _.size(subscriptionProducts) > 0
            ? [
                  {
                      planCode: "1moar",
                      addOns: subscriptionProducts.map((product) => ({
                          code: product.recurlyCode,
                          unitAmount:
                              product.recurlyCode === "gooa-01" && hasFreeTrial
                                  ? _.round(
                                        product.monthlyPrice - (ppcAverages.callTracking + ppcAverages.managementFee)
                                    )
                                  : _.round(product.monthlyPrice),
                          addOnSource: "item",
                      })),
                  },
              ]
            : [];

    const freeTrialPlan =
        _.size(freeTrialSubscriptionProducts) > 0 && hasFreeTrial
            ? [
                  {
                      planCode: "1mtr",
                      addOns: freeTrialSubscriptionProducts.map((product) => ({
                          code: product.recurlyCode,
                          unitAmount: _.round(product.monthlyPrice),
                          addOnSource: "item",
                      })),
                  },
              ]
            : [];

    const profitPlan = groupedCart.profit
        ? [
              {
                  planCode: profitPaymentOption.planCode,
                  addOns: groupedCart.profit.map((product) => {
                      return {
                          code: product.recurlyCode,
                          unitAmount: _.round((product.price / profitPaymentOption.value) * profitPaymentOption.fee),
                          addOnSource: "item",
                      };
                  }),
              },
          ]
        : [];

    const customerNotes = _.flow(
        _.filter((product) => product.defaultQuantity),
        _.map((product) => `${product.name}: ${product.defaultQuantity.label} - ${product.paperType}`),
        _.join(", ")
    )(groupedCart.profit);

    const placeOrder = () => {
        routerHistory.push("/submitted-order");
        dispatch(slices.tasks.actions.set(tasks));
        dispatch(
            actions.createPurchase({
                tokenId: token.id,
                plans: [...subscriptionPlan, ...freeTrialPlan, ...profitPlan],
                user: {
                    firstName: account.firstName,
                    lastName: account.lastName,
                    email: account.email,
                    company: user.practiceName,
                },
                couponCodes: couponCodes,
                customerNotes: customerNotes,
            })
        );
    };

    return (
        <Box>
            <Gradient />
            <AbsolutePositionLogo />
            <Box pb={"44px"} />
            <CheckoutHeader darkNumber={3} header={"Review Order"} />
            <Box pb={"10px"} />
            <Grid gridTemplateColumns={"1fr minmax(320px, 338px) 1fr"}>
                <Box gridColumn={2} px={"10px"}>
                    <Text variant={"body2"} color={"graphiteGray"}>
                        Contact information
                    </Text>
                    <Box pb={"5px"} />
                    <InfoRow label={"Phone"} info={account.phone} />
                    <Box pb={"5px"} />
                    <InfoRow label={"Email"} info={account.email} />
                    <Box pb={"17px"} />
                    <Text variant={"body2"} color={"graphiteGray"}>
                        Order Summary
                    </Text>
                </Box>
            </Grid>
            <Box pb={"7px"} />
            <Grid gridTemplateColumns={"1fr minmax(320px, 479px) 1fr"}>
                <Grid
                    gridColumn={2}
                    gridTemplateColumns={"1fr minmax(320px, 330px) 1fr"}
                    border={"cart"}
                    borderRadius={"20px"}
                >
                    <FlexRowAlignCenter gridColumn={2} justifySelf={"center"} pt={"12px"} pb={"15px"}>
                        <BigSubscriptionIcon />
                        <Box pl={"9px"} />
                        <Text variant={"body2"} color={"graphiteGray"}>
                            <Span fontWeight={"medium"}>Your Subscriptions </Span>– Cancel Anytime
                        </Text>
                    </FlexRowAlignCenter>
                    <Section
                        Icon={ComputerIcon}
                        name={"online"}
                        cost={onlineEstimates.cost}
                        products={groupedCart.online}
                        darkColor={"forrestGreen"}
                        lightColor={"seaGreen"}
                    />
                    <Box pb={"32px"} gridColumn={2} />
                    <Section
                        Icon={GrowthIcon}
                        name={"growth"}
                        cost={growthEstimates.cost}
                        products={groupedCart.growth}
                        darkColor={"deepPetrol"}
                        lightColor={"ipanemaBlue"}
                    />
                    <Box pb={"27px"} gridColumn={2} />
                    <SubscriptionTotal
                        growthCost={growthEstimates.cost}
                        onlineCost={onlineEstimates.cost}
                        groupedCart={groupedCart}
                        coupon={coupon}
                    />
                    <Box pb={"22px"} gridColumn={2} />
                </Grid>
                <Box pb={"22px"} gridColumn={2} />
                <Grid
                    gridColumn={2}
                    gridTemplateColumns={"1fr minmax(320px, 330px) 1fr"}
                    border={"cart"}
                    borderRadius={"20px"}
                >
                    <Text variant={"body2"} color={"graphiteGray"} gridColumn={2} justifySelf={"center"} py={"13px"}>
                        <Span fontWeight={"bold"}>Product Purchases </Span>— Must Be Paid In Full
                    </Text>
                    <Ribbon name={"profit"} Icon={ProfitIcon} backgroundColor={"balticBlue"} />
                    <Box gridColumn={2}>
                        <FlexCol gridColumn={2} px={"12px"}>
                            <Box pb={"7px"} />
                            <Text
                                variant={"label3"}
                                fontWeight={"medium"}
                                color={"balticBlue"}
                                alignSelf={"flex-end"}
                                uppercase
                            >
                                price
                            </Text>
                            <Box pb={"10px"} />
                            {groupedCart.profit
                                ? groupedCart.profit.map((product) => (
                                      <Box pb={"10px"} key={product.id}>
                                          <ProfitProduct product={product} />
                                      </Box>
                                  ))
                                : null}
                            <FlexRowAlignCenter justifyContent={"space-between"} data-testid={"profitTotal"}>
                                <Text color={"nauticBlue"} variant={"body3"} fontWeight={"bold"}>
                                    Profit total:
                                </Text>
                                <Text color={"graphiteGray"} variant={"body3"} fontWeight={"bold"}>
                                    {formatter.format(_.round(profitTotalCost))}
                                </Text>
                            </FlexRowAlignCenter>
                            <Box pb={"20px"} />
                            <Text color={"nauticBlue"} variant={"body3"} fontWeight={"bold"}>
                                Payment Plan
                            </Text>
                        </FlexCol>
                        <Box pb={"10px"} />
                        <FlexRowAlignCenter justifyContent={"space-between"} data-testid={"paymentPlan"}>
                            <FlexRowAlignCenter
                                justifyContent={"center"}
                                border={"1px solid #94A5B5"}
                                height={"25px"}
                                width={"192px"}
                            >
                                <Text variant={"body4"} color={"nauticBlue"}>
                                    {profitPaymentOption.label}
                                </Text>
                            </FlexRowAlignCenter>
                            <Text variant={"body3"} fontWeight={"bold"}>
                                {formatter.format(
                                    _.round((profitTotalCost / profitPaymentOption.value) * profitPaymentOption.fee)
                                )}
                                {profitPaymentOption.value > 1 && "/mo."}
                            </Text>
                        </FlexRowAlignCenter>
                        <Box pb={"20px"} />
                        <ProfitTotal
                            profitTotalCost={profitTotalCost}
                            groupedCart={groupedCart}
                            profitPaymentOption={profitPaymentOption}
                            coupon={coupon}
                        />
                        <Box pb={"20px"} />
                    </Box>
                </Grid>
                <Box gridColumn={2} pb={"40px"} />
                <Grid gridColumn={2} gridTemplateColumns={"1fr minmax(320px, 330px) 1fr"}>
                    <Button backgroundColor={"graphiteGray"} onClick={placeOrder} gridColumn={2}>
                        place order
                    </Button>
                </Grid>
            </Grid>
            <Box pb={"100px"} />
        </Box>
    );
};

const Ribbon = ({ name, Icon, backgroundColor }) => (
    <>
        <Box gridColumn={1} backgroundColor={backgroundColor} height={"30px"} />
        <FlexRowAlignCenter gridColumn={2} backgroundColor={backgroundColor} height={"30px"} width={"100%"}>
            <Text color={"white"} variant={"label1"} fontWeight={"bold"} uppercase pl={"12px"}>
                {name}
            </Text>
            <Box pl={"12px"} />
            <Icon fill={"white"} />
        </FlexRowAlignCenter>
        <Box gridColumn={3} backgroundColor={backgroundColor} height={"30px"} />
    </>
);

const InfoRow = ({ label, info }) => (
    <FlexRowAlignCenter justifyContent={"space-between"}>
        <FlexRowAlignCenter>
            <Text color={"graphiteGray"} variant={"body3"} width={"50px"}>
                {label}
            </Text>
            <Text variant={"body3"}>{info}</Text>
        </FlexRowAlignCenter>
        <Text
            variant={"label3"}
            color={"sienna"}
            fontWeight={"medium"}
            cursor={"pointer"}
            onClick={() => routerHistory.push("/account")}
            uppercase
        >
            edit
        </Text>
    </FlexRowAlignCenter>
);

const Product = ({ product, nameColor, descriptionColor }) => (
    <FlexRowAlignCenter justifyContent={"space-between"} data-testid={product.recurlyCode}>
        <Box>
            <Text color={nameColor} variant={"body3"}>
                {product.name}
            </Text>
            <Box pb={"1px"} />
            <Text variant={"label1"} color={descriptionColor}>
                {product.description}
            </Text>
        </Box>
        <Text variant={"body3"}>{formatter.format(_.round(product.monthlyPrice))}</Text>
    </FlexRowAlignCenter>
);

const ProfitProduct = ({ product }) => (
    <Box data-testid={product.recurlyCode}>
        <FlexRowAlignCenter justifyContent={"space-between"}>
            <Box>
                <Text color={"nauticBlue"} variant={"body3"}>
                    {product.name}
                </Text>
                <Box pb={"1px"} />
                <Text variant={"label1"} color={"balticBlue"}>
                    {product.description}
                </Text>
            </Box>
            <Text variant={"body3"}>{formatter.format(_.round(product.price))}</Text>
        </FlexRowAlignCenter>
        <FlexRowAlignCenter justifyContent={"space-between"}>
            {product.defaultValue ? (
                <>
                    <Text variant={"label1"} color={"balticBlue"}>
                        Training Level:
                    </Text>
                    <Text color={"nauticBlue"} variant={"body4"}>
                        {product.defaultValue.label}
                    </Text>
                </>
            ) : product.defaultQuantity ? (
                <>
                    <FlexRowAlignCenter>
                        <Text variant={"label1"} color={"balticBlue"}>
                            Quantity:
                        </Text>
                        <Box pl={"10px"} />
                        <Text color={"nauticBlue"} variant={"body4"}>
                            {product.defaultQuantity.label}
                        </Text>
                    </FlexRowAlignCenter>
                    <FlexRowAlignCenter>
                        <Text variant={"label1"} color={"balticBlue"}>
                            Paper Type:
                        </Text>
                        <Box pl={"10px"} />
                        <Text color={"nauticBlue"} variant={"body4"}>
                            {product.paperType}
                        </Text>
                    </FlexRowAlignCenter>
                </>
            ) : null}
        </FlexRowAlignCenter>
    </Box>
);

const Section = ({ name, Icon, lightColor, darkColor, products, cost }) => (
    <>
        <Ribbon name={name} Icon={Icon} backgroundColor={lightColor} />
        <FlexCol gridColumn={2} px={"12px"}>
            <Box pb={"7px"} />
            <Text variant={"label3"} fontWeight={"medium"} color={lightColor} alignSelf={"flex-end"} uppercase>
                monthly
            </Text>
            <Box pb={"10px"} />
            {products
                ? products.map((product) => (
                      <Box pb={"10px"} key={product.id}>
                          <Product product={product} descriptionColor={lightColor} nameColor={darkColor} />
                      </Box>
                  ))
                : null}
            <Box pb={"8px"} />
            <FlexRowAlignCenter justifyContent={"space-between"}>
                <Text color={darkColor} variant={"body3"} fontWeight={"bold"}>
                    {_.upperFirst(name)} total due each month:
                </Text>
                <Text color={"graphiteGray"} variant={"body3"} fontWeight={"bold"} data-testid={`${name}Total`}>
                    {formatter.format(_.round(cost))}
                </Text>
            </FlexRowAlignCenter>
        </FlexCol>
    </>
);
