import React, { useCallback, useEffect } from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { Normalize } from "styled-normalize";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { routerHistory } from "./routerHistory";
import theme from "./theme";
import axios from "axios";
import { Description, Loading, PlaceSelect } from "./Components/Pages";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "./utils/ScrollToTop";
import ReactGa from "react-ga";
import { PlaceSelectLoading } from "./Components/Pages/PlaceSelectLoading";
import { ReportLoading } from "./Components/Pages/ReportLoading";
import { OnlineOne } from "./Components/Pages/OnlineOne";
import { OnlineTwo } from "./Components/Pages/OnlineTwo";
import { OnlineThree } from "./Components/Pages/OnlineThree";
import { GrowthOne } from "./Components/Pages/GrowthOne";
import { GrowthTwo } from "./Components/Pages/GrowthTwo";
import { GrowthThree } from "./Components/Pages/GrowthThree";
import { ProfitOne } from "./Components/Pages/ProfitOne";
import { ProfitTwo } from "./Components/Pages/ProfitTwo";
import { Cart } from "./Components/Pages/Cart";
import { Checkout } from "./Components/Pages/Checkout";
import { Account } from "./Components/Pages/Account";
import { ReviewOrder } from "./Components/Pages/ReviewOrder";
import { SubmittedOrder } from "./Components/Pages/SubmittedOrder";
import { Terms } from "./Components/Organisms/Terms";
import { Welcome } from "./Components/Pages/Welcome";
import { EmailLink } from "./Components/Pages/EmailLink";
import _ from "lodash/fp";
import { SentLink } from "./Components/Pages/SentLink";
import { actions, slices } from "./redux";
import { Login } from "./Components/Pages/Login";
import { LocationDetected } from "./Components/Pages/LocationDetected";
import { Pdf, GetLocations, GetReport } from "./Components/Pages/Pdf";
import { jwtDecode } from "jwt-decode";
import { setAuthToken } from "./utils";
import { LoginCode } from "./Components/Pages/LoginCode";

ReactGa.initialize([{ trackingId: "UA-185482476-1" }, { trackingId: "AW-464817204" }]);

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const GlobalStyle = createGlobalStyle`
      body{
          font-family: "Roboto", sans-serif;
          height: 100%;
      }
      
      html {
          box-sizing: border-box;
          -webkit-text-size-adjust:100%;
          -webkit-print-color-adjust: exact;
          height:100%;
      }
      
      *, *:before, *:after {
          box-sizing: inherit;
      }
`;

function App() {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading);
    const origin = window.location.origin;
    // const origin = "swell";
    //const origin = "hpa";

    useSyncCart();

    routerHistory.listen((location) => {
        ReactGa.set({ page: location.pathname });
        ReactGa.pageview(location.pathname);
    });

    useEffect(() => {
        if (origin.includes("bestfordentistry")) {
            dispatch(slices.isBestForDentistry.actions.set(true));
            setFaviconToBestLogo();
        } else if (origin.includes("burkhart")) {
            dispatch(slices.isBurkhart.actions.set(true));
        } else if (origin.includes("hpa")) {
            dispatch(slices.isHpa.actions.set(true));
            setFaviconToHpaLogo();
        } else if (origin.includes("swell")) {
            dispatch(slices.isSwell.actions.set(true));
            setFaviconToSwell();
        }

        if (_.includes("token=", `${window.location}`)) {
            const sessionId = `${window.location}`.split("token=")[1];
            dispatch(actions.getSession(sessionId));
        } else {
            const pathSegments = window.location.pathname.split("/");
            const loginCodeSegment = pathSegments[pathSegments.length - 1];
            const loginCode = Number(loginCodeSegment) ? `/${loginCodeSegment}` : "";
            routerHistory.push("/verify-code" + loginCode);
        }
    }, [dispatch, origin]);

    useKickIfBadToken();

    return (
        <>
            <Normalize />
            <GlobalStyle />
            <ThemeProvider theme={theme}>
                <Router history={routerHistory}>
                    <ScrollToTop />
                    <Switch>
                        <Route path={"/verify-code/:loginCode?"}>
                            <LoginCode />
                        </Route>
                        <Route path={"/welcome"} render={() => <Welcome />} />
                        <Route path={"/get-locations/:practiceName/:zipCode"} render={() => <GetLocations />} />
                        <Route path={"/get-report"} render={() => <GetReport />} />
                        <Route path={"/pdf"} render={() => <Pdf />} />
                        <Route path={"/report-loading"} render={() => <ReportLoading isLoading={loading} />} />
                        <Route path={"/description"} render={() => <Description />} />
                        <Route
                            path={"/place-select"}
                            render={() => (
                                <PlaceSelectLoading isLoading={loading}>
                                    <PlaceSelect />
                                </PlaceSelectLoading>
                            )}
                        />
                        <Route path={"/online-one"} render={() => <OnlineOne />} />
                        <Route path={"/online-two"} render={() => <OnlineTwo />} />
                        <Route path={"/online-three"} render={() => <OnlineThree />} />
                        <Route path={"/growth-one"} render={() => <GrowthOne />} />
                        <Route path={"/growth-two"} render={() => <GrowthTwo />} />
                        <Route path={"/growth-three"} render={() => <GrowthThree />} />
                        <Route path={"/profit-one"} render={() => <ProfitOne />} />
                        <Route path={"/profit-two"} render={() => <ProfitTwo />} />
                        <Route path={"/cart"} render={() => <Cart />} />
                        <Route path={"/email-link"} render={() => <EmailLink />} />
                        <Route path={"/location-detected"} render={() => <LocationDetected />} />
                        <Route path={"/account"} render={() => <Account />} />
                        <Route path={"/login"} render={() => <Login />} />
                        <Route path={"/checkout"} render={() => <Checkout />} />
                        <Route
                            path={"/review-order"}
                            render={() => (
                                <Loading isLoading={loading}>
                                    <ReviewOrder />
                                </Loading>
                            )}
                        />
                        <Route path={"/terms"} render={() => <Terms />} />
                        <Route
                            path={"/submitted-order"}
                            render={() => (
                                <Loading isLoading={loading}>
                                    <SubmittedOrder />
                                </Loading>
                            )}
                        />
                        <Route
                            path={"/sent-link"}
                            render={() => (
                                <Loading isLoading={loading}>
                                    <SentLink />
                                </Loading>
                            )}
                        />
                        <Route path={"*"} element={<Redirect to={"/verify-code"} />}></Route>
                    </Switch>
                </Router>
            </ThemeProvider>
        </>
    );
}

const useSyncCart = () => {
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);
    const leadId = useSelector((state) => state.report?.leadId ?? "");
    const profitPaymentType = useSelector((state) => state.profitPaymentOption.label);

    useEffect(() => {
        if (leadId && !_.includes("token=", `${window.location}`)) {
            dispatch(actions.savePlan(leadId, { cart, profitPaymentType }));
            dispatch(actions.updateSession());
        }
    }, [cart, dispatch, leadId, profitPaymentType]);
};

export default App;

function setFaviconToBestLogo() {
    return document.getElementById("favicon").setAttribute("href", process.env.PUBLIC_URL + "/bestFavicon.ico");
}

function setFaviconToHpaLogo() {
    return document.getElementById("favicon").setAttribute("href", process.env.PUBLIC_URL + "/hpaFavicon.ico");
}

function setFaviconToSwell() {
    return document.getElementById("favicon").setAttribute("href", process.env.PUBLIC_URL + "/swellFavicon.ico");
}

function useKickIfBadToken() {
    const kick = useCallback(() => {
        localStorage.removeItem("jwtToken");
        const pathSegments = window.location.pathname.split("/");
        const loginCodeSegment = pathSegments[pathSegments.length - 1];
        const loginCode = Number(loginCodeSegment) ? `/${loginCodeSegment}` : "";
        routerHistory.push("/verify-code" + loginCode);
    }, []);

    useEffect(() => {
        try {
            const token = localStorage.getItem("jwtToken");

            if (!token) {
                kick();
                return;
            }

            const decoded = jwtDecode(token);
            if (new Date(decoded.expiration) < new Date()) {
                kick();
            }

            setAuthToken(token);
            routerHistory.push("/welcome");
        } catch (e) {
            kick();
        }
    }, [kick]);
}
