import React, { useEffect } from "react";
import { Box, FlexCol, FlexRow, FlexRowAlignCenter, Grid, Span } from "../styledSystemUtilities";
import { AdsIcon, BurkhartLogo2, GrayAscentLogo, LocalPin, ReviewsStars } from "../Atoms/assets";
import { useDispatch, useSelector } from "react-redux";
import { getVisibilityLevel } from "../../utils/getVisibilityLevel";
import _ from "lodash/fp";
import { centsFormatter } from "../../utils/formatter";
import { useParams } from "react-router-dom";
import { actions, slices } from "../../redux";
import { routerHistory } from "../../routerHistory";

const Header = (props) => <Box color={"#4A4A4A"} fontSize={"24px"} fontFamily={"Helvetica Neue"} {...props} />;
const LogoText = (props) => <Box color={"#ADADAD"} fontSize={"14px"} {...props} />;
const BigGreen = (props) => (
    <Box
        color={"forrestGreen"}
        fontSize={"17px"}
        fontFamily={"'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue'"}
        fontWeight={"light"}
        {...props}
    />
);
const TableHeader = (props) => <Box color={"#8B9494"} fontSize={"11px"} fontFamily={"Helvetica Neue"} {...props} />;
const TableBody = (props) => <Box color={"#4A4A4A"} fontSize={"12px"} fontFamily={"Helvetica Neue"} {...props} />;

const RedGuy = (props) => <Span color={"amaranthRed"} fontWeight={"bold"} fontSize={"16px"} {...props} />;
const GreyBody = (props) => <Box color={"#737373"} fontSize={"14px"} {...props} />;
const LilRedGuy = (props) => <RedGuy fontSize={"14"} {...props} />;
const DarkSpan = (props) => <Span color={"#4A4A4A"} fontWeight={"bold"} {...props} />;

const TopFiveTable = ({ seoReport }) => {
    const practice = {
        overallRank: seoReport.overallRank,
        practiceName: seoReport.practiceName,
        placeId: seoReport.placeId,
        styles: {
            color: "amaranthRed",
            fontWeight: "bold",
        },
    };

    const sort = _.orderBy("overallRank", "asc");

    const rows = _.flow(
        sort,
        _.map(_.set("styles", { color: "#4A4A4A" })),
        _.take(5),
        _.concat(practice),
        sort
    )(seoReport.competitors);

    return (
        <>
            <TableHeader>TOP 5 PRACTICES (PLUS YOU) IN YOUR AREA:</TableHeader>
            <Box borderBottom={"2px solid #8B9494"} mb={2} />
            {rows.map((row, index, array) => (
                <FlexRowAlignCenter mb={2} key={row.placeId}>
                    <TableBody mr={6} mb={1} {...row.styles}>
                        {row.overallRank}.
                    </TableBody>
                    <Box width={"100%"} borderBottom={index < array.length - 1 ? "1px solid #EDEDF0" : "none"}>
                        <TableBody mb={1} {...row.styles}>
                            {row.practiceName}
                        </TableBody>
                    </Box>
                </FlexRowAlignCenter>
            ))}
        </>
    );
};

const KeywordTable = ({ keywords }) => {
    return (
        <>
            <Grid gridTemplateColumns={"1fr auto 2px"}>
                <TableHeader>KEYWORD:</TableHeader>
                <TableHeader justifySelf={"center"}>YOUR RANK:</TableHeader>
                <Box gridColumn={"1/-1"} borderBottom={"2px solid #8B9494"} mb={2} />
                {keywords.map((keyword, index, array) => {
                    return (
                        <>
                            <TableBody>{keyword.googleSearchTerm}</TableBody>
                            <TableBody justifySelf={"center"}>{keyword.rank}</TableBody>
                            <Box
                                mt={1}
                                mb={2}
                                gridColumn={"1/-1"}
                                borderBottom={index < array.length - 1 ? "1px solid #EDEDF0" : "none"}
                            />
                        </>
                    );
                })}
            </Grid>
        </>
    );
};

const GoogleReviewsCard = ({ reviewsReport }) => {
    const count = _.countBy((practice) => practice.numReviews > reviewsReport.numReviews, reviewsReport.competitors);

    const practice = {
        numReviews: reviewsReport.numReviews,
        rating: reviewsReport.rating,
        practiceName: reviewsReport.practiceName,
        placeId: reviewsReport.placeId,
        reviewsRank: (count.true ?? 0) + 1,
        styles: {
            color: "amaranthRed",
            fontWeight: "bold",
        },
    };

    const sort = _.orderBy("numReviews", "desc");

    const rows = _.flow(
        sort,
        _.map(_.set("styles", { color: "#4A4A4A" })),
        _.take(5),
        _.concat(practice),
        sort,
        _.map.convert({ cap: false })((row, index) => {
            if (row.placeId === practice.placeId) {
                return row;
            }
            return {
                ...row,
                reviewsRank: index + 1,
            };
        })
    )(reviewsReport.competitors);

    return (
        <>
            <Box>
                <FlexRowAlignCenter mb={4}>
                    <ReviewsStars />
                    <BigGreen ml={4}>
                        Google Reviews &mdash;{" "}
                        <Span fontSize={"14px"} fontFamily={"Helvetica Neue"}>
                            ensure trust and visibility.
                        </Span>
                    </BigGreen>
                </FlexRowAlignCenter>
                <GreyBody mb={6}>
                    Practices with more Google reviews than you: <RedGuy>{count.true ?? 0}</RedGuy>
                </GreyBody>
            </Box>
            <Grid mb={7} gridTemplateColumns={"25px 3fr 1fr 1fr"}>
                <TableHeader gridColumn={"1/3"}>TOP 5 PRACTICES (PLUS YOU) IN YOUR AREA:</TableHeader>
                <TableHeader justifySelf={"center"}>AVERAGE RATING:</TableHeader>
                <TableHeader justifySelf={"center"}>TOTAL REVIEWS:</TableHeader>
                <Box gridColumn={"1/-1"} borderBottom={"2px solid #8B9494"} mb={2} />
                {rows.map((row, index, array) => {
                    return (
                        <>
                            <TableBody mr={6} mb={1} {...row.styles}>
                                {row.reviewsRank}.
                            </TableBody>
                            <TableBody mb={1} {...row.styles}>
                                {row.practiceName}
                            </TableBody>
                            <TableBody justifySelf={"center"} {...row.styles}>
                                {row.rating}
                            </TableBody>
                            <TableBody justifySelf={"center"} {...row.styles}>
                                {row.numReviews}
                            </TableBody>
                            <Box
                                mt={1}
                                mb={2}
                                gridColumn={"2/-1"}
                                borderBottom={index < array.length - 1 ? "1px solid #EDEDF0" : "none"}
                            />
                        </>
                    );
                })}
            </Grid>
        </>
    );
};

const bhWidth = 200;

export const GetLocations = () => {
    const dispatch = useDispatch();
    const { zipCode, practiceName } = useParams();
    const locations = useSelector((state) => state.places);
    useEffect(() => {
        dispatch(slices.user.actions.set({ practiceName, searchArea: zipCode, practiceType: "generalDentist" }));
        dispatch(actions.getPlaces({ practiceName, searchArea: zipCode }));
    }, [zipCode, practiceName, dispatch]);

    useEffect(() => {
        if (locations.length === 0) {
            return;
        }

        routerHistory.push("/get-report");
    }, [locations.length]);

    return <Box />;
};

export const GetReport = () => {
    const dispatch = useDispatch();
    const locations = useSelector((state) => state.places);
    const user = useSelector((state) => state.user);
    const report = useSelector((state) => state.report);
    useEffect(() => {
        dispatch(actions.getReport(locations[0], user));
    }, [dispatch, locations, user]);

    useEffect(() => {
        if (!report) {
            return;
        }

        routerHistory.push("/pdf");
    });

    return <Box />;
};

export const Pdf = () => {
    const report = useSelector((state) => state.report);
    const user = useSelector((state) => state.user);

    const cpc = report.ppc.keywords[0].cpc;
    const cpcLevel = cpc > 10 ? "high" : "low";

    return (
        <Grid id={"pdf-page"} gridTemplateColumns={"1fr 688px 1fr"}>
            <Box gridColumn={2} width={"100%"}>
                <FlexRowAlignCenter justifyContent={"space-between"} mt={"10px"}>
                    <BurkhartLogo2 width={`${bhWidth}px`} height={`${bhWidth * 0.53}px`} />
                    <FlexCol alignItems={"end"}>
                        <GrayAscentLogo width={"160px"} height={"65px"} />
                        <LogoText mt={"-10px"} variant={"label2"}>
                            Predictive Growth Solutions
                        </LogoText>
                    </FlexCol>
                </FlexRowAlignCenter>
                <Header mb={4} mt={"-12px"}>
                    Online Marketing Assessment: <RedGuy>{report.user.practiceName}</RedGuy>
                </Header>
                <Box borderBottom={"2px solid #4A4A4A"} mb={4} />
                <BigGreen mb={5}>
                    Your current overall online assessment (Ideal, Average, or Poor):{" "}
                    <RedGuy>{_.capitalize(getVisibilityLevel(report))}</RedGuy>
                </BigGreen>
                <BigGreen>
                    Competition level for {user.zipCode} zip code:{" "}
                    <RedGuy>{_.round((report.competitionLevel > 1 ? 1 : report.competitionLevel) * 100)}%</RedGuy>
                </BigGreen>
                <GreyBody mb={8}>
                    The higher the percentage, the more competitive it is to rank high in Google Search for your zip
                    code.
                </GreyBody>
                <Grid gridTemplateColumns={"1fr 1fr"} gridColumnGap={"40px"} mb={7}>
                    <Box>
                        <FlexRow alignItems={"end"} mb={4}>
                            <LocalPin />
                            <BigGreen ml={4} lineHeight={"13px"}>
                                Google Local Overall Visibility
                            </BigGreen>
                        </FlexRow>
                        <GreyBody mb={6}>
                            Practices easier to find than you: <RedGuy>{report.seo.overallRank - 1}</RedGuy>
                        </GreyBody>
                        <TopFiveTable seoReport={report.seo} />
                    </Box>
                    <Box px={7} border={"3px solid #EDEDF0"}>
                        <BigGreen mt={"5px"} mb={"11px"}>
                            Individual Keyword Rankings
                        </BigGreen>
                        <GreyBody mb={6}>Your rank for the most searched keywords.</GreyBody>
                        <KeywordTable keywords={report.seo.rankingsFromGoogleSearches} />
                    </Box>
                </Grid>

                <GoogleReviewsCard reviewsReport={report.reviews} />

                <FlexRowAlignCenter mb={4}>
                    <AdsIcon />
                    <BigGreen ml={4}>Google Ads (Pay Per Click)</BigGreen>
                </FlexRowAlignCenter>
                <GreyBody mb={5}>
                    Estimated price per click for your zip code: <RedGuy>{centsFormatter.format(cpc)}</RedGuy> &emsp;
                    This is a <LilRedGuy>{cpcLevel}</LilRedGuy> price per click.
                </GreyBody>
                <GreyBody mb={5}>
                    National Average: <DarkSpan>$8.25</DarkSpan>
                </GreyBody>
                <GreyBody mb={5} fontSize={"12.5px"}>
                    The price per click is an indication of how competitive the online advertising is in your area. As
                    more practices run ads in an area it drives the price up. The lower the price, the more likely it is
                    you can acquire new patients at a lower cost.{" "}
                </GreyBody>
                <BigGreen>General Recommendations: </BigGreen>
                <GreyBody>
                    <DarkSpan>Search — </DarkSpan> 82% of new patients end up choosing one of the top 5 practices in
                    their local search. You should target top 5 individual keyword rankings and top 5 overall.
                    <br />
                    <DarkSpan>Reviews — </DarkSpan> Generate as many reviews as possible to build trust and improve
                    Google ranking.
                    <br />
                    <DarkSpan>Google Ads —</DarkSpan>
                    {cpcLevel === "high"
                        ? "Due to your area’s high price per click, It is important to be prudent with Google Ads for new patient acquisition and improving Google ranking."
                        : "Due to your area’s low price per click, it would be smart to run Google Ads for new patient acquisition and to improve Google ranking."}
                    <br />
                    <br />
                    Your Burkhart Account Manager will contact you to schedule a consultation to discuss this data in
                    detail and ideal digital marketing solutions with an Ascent Marketing Specialist. Or, schedule a
                    consultation online with Mike Sieber at{" "}
                    <a
                        style={{ fontWeight: "bold", color: "#737373", textDecoration: "none" }}
                        href={"https://ascentpgs.com/consultation"}
                    >
                        ascentpgs.com/consultation.
                    </a>
                </GreyBody>
            </Box>
        </Grid>
    );
};
