import styled from "styled-components";
import React from "react";
import _ from "lodash/fp";
import { round } from "lodash";
import { Dot } from "../Atoms";
import { all, Box, FlexRow, Span } from "../styledSystemUtilities";

const Table = styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
`;

const Td = styled.td`
    color: #7f7f7f;
    height: 35px;

    border-top: ${(props) => props.theme.borders.green};
    text-align: center;
    font-size: 12px;
    ${all}
`;

const TdA = styled(Td)`
    background-color: #ddebe3;
    width: 52px;
    ${all}
`;

const TdB = styled(Td)`
    background-color: white;
    width: 52px;
    border-right: ${(props) => props.theme.borders.green};
    ${all}
`;

const TdName = styled(Td)`
    border-left: ${(props) => props.theme.borders.green};
    padding-left: 12px;
    text-align: left;
    ${all}
`;

const Th = styled.th`
    color: #a7a9ac;
    font-size: 9px;
    font-weight: 500;
    padding-bottom: 5px;
    ${all}
`;

export const CompetitorsTable = ({ competitors, topCompData, target, headings, order = "asc", dataTestId }) => {
    const sorted = _.flow([
        _.concat(topCompData[0]),
        _.orderBy("x", order),
        _.take(5),
        _.concat(target[0]),
        _.orderBy("x", order),
    ])(competitors);
    const hideTdA = headings.length === 2;

    return (
        <Table data-testid={dataTestId}>
            <thead>
                <tr>
                    <Th style={{ textAlign: "left" }}>{headings[0]}</Th>
                    {hideTdA ? null : <Th>{headings[1]}</Th>}
                    <Th whiteSpace={hideTdA ? "nowrap" : "normal"}>{_.takeRight(1, headings)}</Th>
                </tr>
            </thead>
            <tbody>
                {sorted.map((practice, index, array) => {
                    if (practice) {
                        if (_.get("placeId", practice) === _.get("0.placeId", target)) {
                            return (
                                <tr key={practice.placeId} data-testid={`row${index + 1}`}>
                                    <TdName border={"thickGreen"} borderRight={"none"}>
                                        <FlexRow alignItems={"center"}>
                                            <Dot flexShrink={0} fill={"sienna"} />
                                            <Box mr={2} />
                                            <Span fontWeight={"bold"}>{practice.practiceName}</Span>
                                        </FlexRow>
                                    </TdName>
                                    {hideTdA ? null : (
                                        <TdA
                                            color={"white"}
                                            backgroundColor={"sienna"}
                                            borderTop={"thickGreen"}
                                            borderBottom={"thickGreen"}
                                            fontWeight={"bold"}
                                        >
                                            {round(practice.y, 1)}
                                        </TdA>
                                    )}
                                    <TdB
                                        color={"white"}
                                        backgroundColor={hideTdA ? "sienna" : "amaranthRed"}
                                        border={"thickGreen"}
                                        borderLeft={"none"}
                                        fontWeight={"bold"}
                                    >
                                        {practice.x}
                                    </TdB>
                                </tr>
                            );
                        } else if (_.get("placeId", practice) === _.get("0.placeId", topCompData)) {
                            return (
                                <tr key={practice.placeId} data-testid={`row${index + 1}`}>
                                    <TdName>
                                        <FlexRow alignItems={"center"}>
                                            <Dot flexShrink={0} fill={"auratiumGreen"} />
                                            <Box mr={2} />
                                            {practice.practiceName}
                                        </FlexRow>
                                    </TdName>
                                    {hideTdA ? null : <TdA>{practice.y}</TdA>}
                                    <TdB>{practice.x}</TdB>
                                </tr>
                            );
                        }
                        const borderBottom = index === array.length - 1 ? "green" : "none";
                        return (
                            <tr key={practice.placeId} data-testid={`row${index + 1}`}>
                                <TdName borderBottom={borderBottom}>{practice.practiceName}</TdName>
                                {hideTdA ? null : <TdA borderBottom={borderBottom}>{practice.y}</TdA>}
                                <TdB borderBottom={borderBottom}>{practice.x}</TdB>
                            </tr>
                        );
                    }
                    return null;
                })}
            </tbody>
        </Table>
    );
};
